import React, { useEffect, useState } from 'react'
import Header from '../sections/Header'
import Hero from './../sections/PrivacyPolicy/Hero';
import Footer from './../sections/Footer';
import HeadingLine from '../components/HeadingLine';
import TermsAndConditionsCard from './../components/TermsAndConditions/TermsAndConditionsCard';
import { supabase } from '../lib/supabase';

function PrivacyPolicy() {

    const [loading, setLoading] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    // Fetch data from Supabase
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchHeaderData = async () => {
            try {
                setLoading(true);
                // Fetch the header data where type is 'whybcs'
                const { data: headerData, error: headerError } = await supabase
                    .from('privacy_policy')
                    .select('*');

                if (headerError) throw headerError;

                setPrivacyPolicy(headerData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching header data:", error);
            }
        };

        fetchHeaderData();
    }, []);

    return (
        <>
            <Header />
            <Hero />
            <div className='w-full container flex flex-col justify-center items-center gap-4 mx-auto my-20 text-justify text-sm lg:text-md whitespace-normal break-words tracking-tight'>
                <div className='w-2/3 flex flex-col justify-center items-center gap-3 text-justify'>
                    {privacyPolicy.map((item, index) => (
                        <>

                            {(item.title != null && item.title != "") ? <HeadingLine data={item.title} /> : null}
                            {item.description != null && item.description != "" ? <p className='w-full text-left'>{item.description}</p> : null}
                            {(item.point_title != null && item.point_title != "") ?
                                <TermsAndConditionsCard tagline={item.point_title} data={item.point_description} /> :
                                null
                            }
                            {
                                (item.point_title == null || item.point_title == "") && (item.point_description != null && item.point_description != "") ?
                                    <TermsAndConditionsCard tagline={""} data={item.point_description} /> : null
                            }

                        </>
                    ))}

                    <HeadingLine data={"Contact Information"} />
                    <TermsAndConditionsCard data={"If you have any questions or concerns about this Privacy Policy, please contact us at:"} />
                    <div className=' w-full flex flex-col justify-start items-center gap-4 px-10'>
                        <div className='w-full flex flex-row justify-start gap-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-primary ">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                            </svg>
                            <p>(302) 244-0434</p>
                        </div>
                        <div className='w-full flex flex-row justify-start gap-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                            <p>info@billingcaresolutions.com</p>
                        </div>
                        <div className='w-full flex flex-row justify-start gap-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>

                            <p >30 N GOULD, ST, STE R SHERIDAN, WY 82801</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />

        </>
    )
}

export default PrivacyPolicy