import React from 'react'

function Loader() {
    return (
        <div className="w-full flex justify-center items-center h-96">
            <div className="loader"></div> {/* You can replace this with any loader UI */}
        </div>
    )
}

export default Loader